<template>
  <div
    class="wrapper-cards moveUp"
    :style="{ direction: direction }"
    v-scrollanimation
  >
    <q-card class="my-card" flat bordered>
      <q-card-section class="row" :style="{ minHeight: '200px', padding: '0' }">
        <q-card-section
          class="
            q-pt-xs
            flex
            items-center
            col-xl-7 col-lg-7 col-md-7 col-sm-12 col-sx-12
          "
        >
          <div class="text">
            <div class="text-h5 q-mt-sm q-mb-lg">{{ title }}</div>
            <div class="text-caption">
              {{ text }}
            </div>
          </div>
        </q-card-section>
        <q-img
          class="
            rounded-borders
            image
            col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12
          "
          loading="lazy"
          :src="`${
            $q.screen.xl || $q.screen.lg || $q.screen.md
              ? imageSrcCol
              : imageSrcRow
          }`"
        />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: ["direction", "title", "text", "imageSrcCol", "imageSrcRow"],
  setup() {
    return {
      slide: ref(1),
      fullscreen: ref(false),
    };
  },
};
</script>

<style scoped>
@import "../styles/globalStyles.scss";

.wrapper-cards {
  padding-top: 30px;
  padding-bottom: 10px;
}

.my-card {
  width: 100%;
  background: none;
}

.text {
  padding: 20px 0;
  text-align: left;
  direction: ltr;
}

.text-h5 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #030730;
  text-decoration: underline;
}

.text-caption {
  color: black;
  padding-top: 7px;
  font-size: 1.3rem;
  line-height: 1.6rem;
}

@media (max-width: 1024px) {
  .text {
    padding: 10px 0;
  }
  .text-h5 {
    font-size: 1.7rem;
  }
}
</style>
